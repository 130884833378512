<template>
    <div class="create_management">
        <div v-loading="is_panding" class="create_management-box">
            <div class="create_management-box-title">
                <h3 v-if="!management_for_edit">הוספת נוהל ל{{dep.name}}</h3>
                <h3 v-else>עריכת נוהל ל{{dep.name}}</h3>
                <div class="exit-btn">
                    <i @click="$emit('close')" class="material-icons">close</i>
                </div>
            </div>
            <div class="create_management-box-content">
                <label>
                    <span style="font-weight: 400;">כותרת:</span>
                    <el-input style="margin:5px 0;" v-model="new_data.name" placeholder="שם הנוהל" />
                </label>
                <label>
                    <span style="font-weight: 400;">תיאור:</span>
                    <el-input
                        class="description"
                        style="margin:5px 0;"
                        v-model="new_data.description"
                        :rows="5"
                        type="textarea"
                        placeholder="תיאור..."
                    />
                </label>
                <h2>קבצי נספחים:</h2>
                <div style="margin-bottom: 10px; border-bottom: 1px solid lightgray;" class="create_management-box-content-images">
                    <div class="image">
                        <i @click="input_file_attachment.click()" class="material-icons" style="cursor:pointer; color: blue; font-size: 30px;">add</i>
                    </div>
                    <template v-for="attachment in new_data.attachments" :key="attachment.url">
                        <div style="cursor: pointer; user-select: none;" class="attachment-image">
                            <div class="image">
                                <a :href="attachment.url" target="_blank" rel="noopener noreferrer">
                                    <img :src=" fileTypeIcons.get(attachment.type)" alt="תמונה">
                                </a>
                            </div>
                            <div class="file-name">
                                <p>{{attachment.name}}</p>
                            </div>
                            <i @click="handle_delete_attachment(attachment.url)" class="material-icons delete-icon" style="cursor:pointer; color: red; font-size: 20px;">delete</i>
                        </div>
                    </template>
                </div>
                <h2>גלריית תמונות:</h2>
                <div class="create_management-box-content-images">
                    <div class="image">
                        <i @click="input_file.click()" class="material-icons" style="cursor:pointer; color: blue; font-size: 30px;">add</i>
                    </div>
                    <template v-for="image in new_data.images" :key="image.url">
                        <div class="image">
                            <img :src="image.url" alt="תמונה">
                            <i @click="handle_delete_image(image.url)" class="material-icons delete-icon" style="cursor:pointer; color: red; font-size: 20px;">delete</i>
                        </div>
                    </template>
                </div>
            </div>
         
            <div class="create_management-box-footer">
                <el-button v-if="!management_for_edit" @click="handle_submit" style="width: 80%;" type="success">הוספה</el-button>
                <el-button v-else="!management_for_edit" @click="handle_submit_edit" style="width: 80%;" type="warning">עדכון</el-button>
            </div>
        </div>
        <input @change="handle_upload_img" v-show="false" ref="input_file" type="file">
        <input @change="handle_upload_attachment" v-show="false" ref="input_file_attachment" type="file">
    </div>
</template>

<script>
import { ref } from 'vue'
import {slide_pop_error,alert_confirm,alert} from '../../../../../Methods/Msgs'
import useStorageWithShrink from '../../../../../Methods/useStorageWithShrink'
import {add_new_managment_and_update_db,update_managments_in_dep_db} from '../store'
export default {
    emits:['close'],
    props:['dep','management_for_edit'],
    setup (props,{emit}) {
        
        const {url,uploadResizeImage,delete_image_by_url,uploadImage} = useStorageWithShrink()

        const is_panding = ref(false)

        const input_file = ref()
        const input_file_attachment = ref()

        const new_data = ref({
            created_at: new Date().toISOString(),
            name:'',
            description:'',
            images:[],
            attachments:[],
        })

        const fileTypeIcons = ref(new Map([
            ["Word", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fdoc_375x375.png?alt=media&token=d4f50ee5-3ea8-461e-aaf1-f554abf9a714"],
            ["Excel", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fxls_375x375.png?alt=media&token=5b534af5-6e3d-41e6-8a1a-abc627211353"],
            ["PDF", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fpdf_375x375.png?alt=media&token=13037383-f80c-4669-ae8a-ce85f354fc2f"],
            ["Google Docs", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fdoc_375x375.png?alt=media&token=d4f50ee5-3ea8-461e-aaf1-f554abf9a714"]
        ]));

        const handle_upload_img = (async e => {
            try{
                is_panding.value = true
                const file = e.target.files[0]; // Get the first selected file
                if (file) {
                    const allowedTypes = ["image/png", "image/jpeg","image/jpg"];
                    if (!allowedTypes.includes(file.type)) {
                        throw new Error('עליך להעלות קובץ מסוג תמונה!')
                    }
                    if(!new_data.value.name){
                        throw new Error('עליך לרשום כותרת לפני העלאת התמונות!')
                    }

                    await uploadResizeImage(file,`ManagmentsApp/${new_data.value.name}`)

                    new_data.value.images.push({
                        url:url.value
                    })

                    
                }
            }catch(err){
                slide_pop_error(err.message)
                console.error(err.message);
            }finally{
                is_panding.value = false
                e.target.value = "";
            }
        })

        const handle_upload_attachment = (async e => {
            try {
                is_panding.value = true;
                
                const file = e.target.files[0];
                const googleDocLink = new_data.value.googleDocLink;

                if (file) {
                    // Allow Word, Excel, and PDF file types
                    const allowedTypes = {
                        "application/msword": "Word",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Word",
                        "application/vnd.ms-excel": "Excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel",
                        "application/pdf": "PDF"
                    };
                    
                    const fileType = allowedTypes[file.type];
                    if (!new_data.value.name) {
                        throw new Error('עליך לרשום כותרת לפני העלאת הקבצים!');
                    }
                    if (!fileType) {
                        throw new Error('עליך להעלות קובץ מסוג Word, Excel, או PDF בלבד!');
                    }
                    
                    
                    await uploadImage(file,`ManagmentsAppAttachments/${new_data.value.name}`)
                    new_data.value.attachments.push({ url: url.value,name:file.name, type: fileType });
                    console.log(new_data.value.attachments);
                    
                } else if (googleDocLink) {
                    // Check if the Google Docs link is valid
                    const isGoogleDoc = googleDocLink.startsWith("https://docs.google.com");
                    if (!isGoogleDoc) {
                        throw new Error('נא להזין קישור תקין ל-Google Docs!');
                    }
                    new_data.value.attachments.push({ url: googleDocLink, type: 'Google Docs' });
                } else {
                    throw new Error('עליך לבחור קובץ או להזין קישור ל-Google Docs!');
                }
                
            } catch (err) {
                slide_pop_error(err.message);
                console.error(err.message);
            } finally {
                is_panding.value = false;
                e.target.value = "";
            }
        });

        const handle_delete_image = async(image_url) => {
            try{
                const index = new_data.value.images.findIndex(({url}) => url == image_url)
                if(index != -1){
                    alert_confirm('להסיר את התמונה?')
                    .then(async(res) => {
                        if(res.isConfirmed){
                            new_data.value.images.splice(index,1)
                            await delete_image_by_url(image_url)
                        }
                    })
                }
            }catch(err){
                console.error(err);
            }
        }

        const handle_delete_attachment = async(attachment_url) => {
            try{
                const index = new_data.value.attachments.findIndex(({url}) => url == attachment_url)
                if(index != -1){
                    alert_confirm('להסיר את הקובץ?')
                    .then(async(res) => {
                        if(res.isConfirmed){
                            new_data.value.attachments.splice(index,1)
                            await delete_image_by_url(attachment_url)
                        }
                    })
                }
            }catch(err){
                console.error(err);
            }
        }

        const verification = () => {
            if(!new_data.value.name){
                throw new Error('עליך לרשום כותרת!')
            }
            if(!new_data.value.description){
                throw new Error('עליך לרשום תיאור!')
            }
        }

        const handle_submit = async() => {
            try {
                verification()
                is_panding.value = true
                await add_new_managment_and_update_db(props.dep,new_data.value)
                props.dep.managements.push(new_data.value)
                alert('success','הצלחה',`הנוהל ${new_data.value.name} הוסף בהצלחה למערכת`)
                emit('close')

            } catch (err) {
                slide_pop_error(err.message)
                console.error(err);
                
            }finally{
                is_panding.value = false
            }
        }

        const handle_submit_edit = async() => {
            try {
                verification()
                is_panding.value = true
                const index = props.dep.managements.findIndex(management => management.created_at == new_data.value.created_at)
                console.log(index);
                if(index != -1){
                    props.dep.managements[index] = new_data.value
                    await update_managments_in_dep_db(props.dep)
                    alert('success','הצלחה',`הנוהל ${new_data.value.name} עודכן בהצלחה למערכת`)
                    emit('close')
                }else{
                    throw new Error('הנוהל הזה לא נמצא!')
                }


            } catch (err) {
                slide_pop_error(err.message)
                console.error(err);
                
            }finally{
                is_panding.value = false
            }
        }

        if(props.management_for_edit){
            new_data.value = props.management_for_edit
            if(!new_data.value.attachments){
                new_data.value.attachments = []
            }
        }
        
        return {
            new_data,
            input_file,
            input_file_attachment,
            handle_upload_img,
            is_panding,
            handle_delete_image,
            handle_submit,
            handle_submit_edit,
            handle_upload_attachment,
            handle_delete_attachment,
            fileTypeIcons,
        }
    }
}
</script>

<style scoped>
    .create_management{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .create_management-box{
        width: 100%;
        max-width: 500px;
        height: 70%;
        background: #fff;
        border-radius: 10px;
        color: #333;
        display: flex;
        flex-direction: column;
    }
    .create_management-box-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
    }
    .create_management-box-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
        padding: 10px;
    }
    .create_management-box-content-images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        padding: 10px;
    }
    .create_management-box-content-images > .image{
        position: relative;
        width: calc((100% / 3) - 2px);
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightblue;
    }

    .create_management-box-content-images > .image > img{
        max-width: 100%;
        max-height: 100%;
    }

    .create_management-box-content-images > .attachment-image {
        position: relative;
        width: calc((100% / 3) - 2px);
        height: 100px;
        border: 1px solid lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .create_management-box-content-images > .attachment-image > .image {
        width: 100%;
        height: 70px; /* Adjusted to leave space for file name and delete icon */
        position: relative;
    }

    .create_management-box-content-images > .attachment-image > .image > a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .create_management-box-content-images > .attachment-image > .image > a > img {
        max-width: 80%; /* Adjusted to fit better */
        max-height: 80%;
    }

    .create_management-box-content-images > .attachment-image > .file-name {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 12px;
        color: #333;
        height: 30px;
    }

   

    .delete-icon{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1100;
    }
    .create_management-box-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    .description{
        white-space: pre-wrap;
    }

</style>